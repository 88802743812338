/* Card CSS */

  .card_title {
    font-size: 25px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .card_inner {
    width: inherit;
    height: inherit;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    border-radius: 8px;
  }
  
  
  
  .circle {
    width: 80px;
    height: 80px;
    background: radial-gradient(#3586ff, #FEFBF6);
    border-radius: 50%;
    position: absolute;
    animation: move-up6 2s ease-in infinite alternate-reverse;
  }
  
  .circle:nth-child(1) {
    top: -15px;
    left: -15px;
  }
  
  .circle:nth-child(2) {
    bottom: -15px;
    right: -30px;
    animation-name: move-down1;
  }
  
  @keyframes move-up6 {
    to {
      transform: translateY(-10px);
    }
  }
  
  @keyframes move-down1 {
    to {
      transform: translateY(10px);
    }
  }
  
  /* Card CSS */
  