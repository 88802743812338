.main_Image {
  width: 100vh;
  height: 100vh;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 10px;
  overflow: hidden; /* Ensure the image doesn't overflow the container */
}

.main_Image img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Maintain aspect ratio without cropping */
}

@media screen and (max-width: 768px) {
  .main_Image {
    width: auto;
    height: auto;
  }
}
