#root {
  font-family: "EB Garamond", serif;
}
main {
  min-height: 80vh;
}

/* Add this CSS in your project's stylesheet */
.welcome-heading {
  text-align: center;
  font-size: 50px;
  margin-top: 40px;
  margin-bottom: 40px;
  color: #000;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.4); /* Add a subtle shadow */
  animation: fadeInUp 1.5s ease-in-out;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.card {
  margin-left: 20px;
  width: 190px;
  height: 354px;
  transition: all 0.2s;
  position: relative;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.04);
}
/* Navbar CSS */
.navbar {
  padding-top: 25px;
  padding-bottom: 25px;
  border: 0;
  border-radius: 0;
  margin-bottom: 50px;
  font-size: 18px;
  letter-spacing: 4px;
}

.navbar-brand {
  font-size: 5rem;
}
/* Navbar CSS */

/* Batman-Button CSS */
.batman {
  border: none;
  position: relative;
  width: 200px;
  height: 73px;
  padding: 10;
  z-index: 2;
  -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='868' width='2500' viewBox='0 0 726 252.17'%3E%3Cpath d='M483.92 0S481.38 24.71 466 40.11c-11.74 11.74-24.09 12.66-40.26 15.07-9.42 1.41-29.7 3.77-34.81-.79-2.37-2.11-3-21-3.22-27.62-.21-6.92-1.36-16.52-2.82-18-.75 3.06-2.49 11.53-3.09 13.61S378.49 34.3 378 36a85.13 85.13 0 0 0-30.09 0c-.46-1.67-3.17-11.48-3.77-13.56s-2.34-10.55-3.09-13.61c-1.45 1.45-2.61 11.05-2.82 18-.21 6.67-.84 25.51-3.22 27.62-5.11 4.56-25.38 2.2-34.8.79-16.16-2.47-28.51-3.39-40.21-15.13C244.57 24.71 242 0 242 0H0s69.52 22.74 97.52 68.59c16.56 27.11 14.14 58.49 9.92 74.73C170 140 221.46 140 273 158.57c69.23 24.93 83.2 76.19 90 93.6 6.77-17.41 20.75-68.67 90-93.6 51.54-18.56 103-18.59 165.56-15.25-4.21-16.24-6.63-47.62 9.93-74.73C656.43 22.74 726 0 726 0z'/%3E%3C/svg%3E")
    no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='868' width='2500' viewBox='0 0 726 252.17'%3E%3Cpath d='M483.92 0S481.38 24.71 466 40.11c-11.74 11.74-24.09 12.66-40.26 15.07-9.42 1.41-29.7 3.77-34.81-.79-2.37-2.11-3-21-3.22-27.62-.21-6.92-1.36-16.52-2.82-18-.75 3.06-2.49 11.53-3.09 13.61S378.49 34.3 378 36a85.13 85.13 0 0 0-30.09 0c-.46-1.67-3.17-11.48-3.77-13.56s-2.34-10.55-3.09-13.61c-1.45 1.45-2.61 11.05-2.82 18-.21 6.67-.84 25.51-3.22 27.62-5.11 4.56-25.38 2.2-34.8.79-16.16-2.47-28.51-3.39-40.21-15.13C244.57 24.71 242 0 242 0H0s69.52 22.74 97.52 68.59c16.56 27.11 14.14 58.49 9.92 74.73C170 140 221.46 140 273 158.57c69.23 24.93 83.2 76.19 90 93.6 6.77-17.41 20.75-68.67 90-93.6 51.54-18.56 103-18.59 165.56-15.25-4.21-16.24-6.63-47.62 9.93-74.73C656.43 22.74 726 0 726 0z'/%3E%3C/svg%3E")
    no-repeat 50% 50%;
  -webkit-mask-size: 100%;
  cursor: pointer;
  background-color: transparent;
  transform: translateY(8px);
}

.batman:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0px 0 0 0 white;
  transition: all 2s ease;
}

.batman:hover:after {
  box-shadow: 0px -13px 56px 12px #ffffffa6;
}

.batman span {
  position: absolute;
  width: 100%;
  font-size: 15px;
  font-weight: 100;
  left: 50%;
  top: 39%;
  letter-spacing: 3px;
  text-align: center;
  transform: translate(-50%, -50%);
  color: black;
  transition: all 2s ease;
}

.batman:hover span {
  color: white;
}

.batman:before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background-color: black;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 1s ease;
}

.batman:hover:before {
  width: 100%;
}
/* Batman-Button CSS */

/* Form-Component CSS */

form {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 24px;
}

/*Login*/
.login {
  position: relative;
  width: 100%;
  height: 100%;
}

.login label {
  margin: 25% 0 5%;
}

.label {
  color: black;
  font-size: 2rem;
  justify-content: left;
  display: flex;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.input {
  width: 100%;
  height: 60px;
  background: black;
  font-size: 1rem;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 4px;
}

/*Form Button*/
form .loginButton {
  position: relative;
  margin-top: 10px;
  padding: 1em 1.8em;
  outline: none;
  border: 1px solid #303030;
  background: #212121;
  color: #3586ff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 15px;
  overflow: hidden;
  transition: 0.2s;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
}

form .loginButton:hover {
  box-shadow: 0 0 10px #3586ff, 0 0 25px #7f5283, 0 0 50px #3586ff;
  transition-delay: 0.6s;
}

form .loginButton span {
  position: absolute;
}

form .loginButton span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #3586ff);
}

form .loginButton:hover span:nth-child(1) {
  left: 100%;
  transition: 0.7s;
}

form .loginButton span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #7f5283);
}

form .loginButton:hover span:nth-child(3) {
  right: 100%;
  transition: 0.7s;
  transition-delay: 0.35s;
}

form .loginButton span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #3586ff);
}

form .loginButton:hover span:nth-child(2) {
  top: 100%;
  transition: 0.7s;
  transition-delay: 0.17s;
}

form .loginButton span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #7f5283);
}

form .loginButton:hover span:nth-child(4) {
  bottom: 100%;
  transition: 0.7s;
  transition-delay: 0.52s;
}

form .loginButton:active {
  background: #ae00af;
  background: linear-gradient(to top right, #ae00af, #7f5283);
  color: #bfbfbf;
  box-shadow: 0 0 8px #3586ff, 0 0 8px #7f5283, 0 0 8px #3586ff;
  transition: 0.1s;
}

form
  .loginButton:active
  span:nth-child(1)
  span:nth-child(2)
  span:nth-child(2)
  span:nth-child(2) {
  transition: none;
  transition-delay: none;
}

/*Form Button*/
/* Form-Component CSS */
